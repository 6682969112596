<template>
  <b-card title="">
    <b-row class="justify-content-start d-flex">
      <b-tabs content-class="mt-3">
        <b-tab title="Ativos" @click="(userList = false), getAllUsers()" active>
        </b-tab>
        <b-tab title="Inativos" @click="(userList = true), getAllUsers()">
        </b-tab>
      </b-tabs>
    </b-row>

    <b-row class="justify-content-between d-flex">
      <b-col>
        <b-row class="ml-2">
          <b-form-group>
            <b-form-input
              type="text"
              v-model="search"
              placeholder="Buscar item"
            ></b-form-input>
          </b-form-group> </b-row
      ></b-col>

      <b-col class="d-flex justify-content-end">
        <b-row>
          <b-button
            class="mb-4 mr-2"
            variant="primary"
            @click="$router.push({ name: 'new-user' })"
          >
            Novo Workspace +
          </b-button>
        </b-row>
      </b-col>
    </b-row>

    <b-table
      responsive="sm"
      small
      :items="filter()"
      :fields="fields"
      class="w-100"
      :per-page="30"
      :current-page="0"
    >
      <template v-slot:cell(actions)="{ item }">
        <ActionsUsers :item="item" />
      </template>

      <template v-slot:cell(access)="{ item }">
        <b-row class="colum-desactivate justify-content-between d-flex p-1">
          <b-form-checkbox
            id="checkbox-1"
            v-model="item.is_admin"
            name="checkbox-1"
            value="true"
            unchecked-value="false"
          >
            Adm. Sistema
          </b-form-checkbox>

          <b-form-checkbox
            id="checkbox-1"
            v-model="item.is_blocked"
            name="checkbox-1"
            value="true"
            unchecked-value="false"
          >
            Inativo
          </b-form-checkbox>
        </b-row>
      </template>

      <template v-slot:cell(workspaces)="{ item }">
        <div v-html="item.workspaces"></div>
      </template>
    </b-table>
    <b-pagination
      class="justify-content-center"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @change="getPage"
      aria-controls="my-table"
    ></b-pagination>
    <div class="mr-2 d-flex justify-content-end">
      <strong>Total de usuários: {{ totalRows }} </strong>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
  BTable,
  BButton,
  BRow,
  BFormCheckbox,
  BCol,
  BFormInput,
  BFormGroup,
  BPagination,
} from 'bootstrap-vue';
import ActionsUsers from './components/ActionsUsers.vue';
export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BTable,
    BButton,
    BRow,
    ActionsUsers,
    BFormCheckbox,
    BCol,
    BFormInput,
    BFormGroup,
    BPagination,
  },
  data: () => ({
    search: '',
    fields: [
      {
        key: 'actions',
        label: 'Ações',
        sortable: false,
      },
      {
        key: 'username',
        label: 'Nome',
        sortable: true,
      },
      {
        key: 'email',
        label: 'Email',
        sortable: true,
      },
      {
        key: 'workspaces',
        label: 'Area de trabalho',
        sortable: true,
      },
      {
        key: 'access',
        label: 'Permissões',
        sortable: true,
      },
    ],
    items: [],
    userList: false,
    perPage: 30,
    rows: 0,
    currentPage: 0,
    totalRows: 0,
  }),
  created() {
    this.getAllUsers();
  },
  methods: {
    getPage(val) {
      this.getAllUsers(val);
    },
    getAllUsers(val) {
      this.currentPage = val ? val : 1;
      this.items = [];
      if (!this.userList) {
        this.$store
          .dispatch('getAllUsers', {
            workspace_id: null,
            page: this.currentPage,
            perPage: this.perPage,
            inactive: false,
          })
          .then((resp) => {
            if (resp) {
              this.items = this.groupUsers(resp.data);
              this.totalRows = resp.total
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.getInactivesUsers();
      }
    },

    getInactivesUsers() {
      this.$store
        .dispatch('getAllUsers', { inactive: true })
        .then((resp) => {
          console.log(resp)
          this.items = this.groupUsers(resp.data);
          this.totalRows = resp.total

      
        })
        .catch((err) => {
          console.log(err);
        });
    },

    groupUsers(users) {
      const grouped = {};

      users.forEach((user) => {
        const key = `${user.username}_${user.email}`;

        if (!grouped[key]) {
          grouped[key] = {
            id: user.id,
            username: user.username,
            email: user.email,
            workspaces: [],
            is_admin: user.is_admin,
            is_blocked: user.is_blocked,
          };
        }

        if (user.accesses) {
          user.accesses.forEach((access) => {
            if (access && access.workspaces) {
              grouped[key].workspaces.push(access.workspaces.workspace_title);
            }
          });
        }
      });

      return Object.values(grouped).map((user) => ({
        ...user,
        workspaces:
          user.workspaces.length > 0
            ? user.workspaces
                .map((workspace, index) => `${index + 1}. ${workspace}`)
                .join('<br>')
            : '<b>SEM VÍNCULO</b>',
      }));
    },

    filter() {
      if (this.search.trim() === '') {
        return this.items;
      } else {
        return this.items.filter((item) => {
          return (
            item.username.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1 ||
            item.email.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      }
    },

    cleanFilter() {
      this.search = '';
    },
  },
};
</script>

<style scoped>
.colum-desactivate {
  pointer-events: none !important;
}
.b-table td {
  white-space: pre-line;
}
</style>
